import Axios from "../../Lib/Axios";

export const listCareers = (page, keySearch) => {
    return Axios.get(`careers/${page}?keyword=${keySearch}`);
}


export const detailCareer = (slug) => {
    return Axios.get(`career/${slug}`);
}

export const applyJob = (id, values, file) => {
    let formData = new FormData()
    formData.append('name', values.name);
    formData.append('phone', values.phone);
    formData.append('email', values.email);
    formData.append('file',file);
    return Axios.post(`apply-job/${id}`, formData,
        {headers: {'content-type': 'application/x-www-form-urlencoded'}}
    );
}

export const contact = (id, formData ) => {

    return Axios.post(`contact`, formData );
}