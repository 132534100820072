import React, {useEffect, useState} from "react";
import {activeMenu, getConfig, getFeaturesArticles} from "./Components/HomeComponent";
import SelectLanguage from "../Layout/SelectLanguage";

function Contact() {
    const [configSite, setConfigSite] = useState(null);
    useEffect(() => {
        getConfig().then(result => {
            setConfigSite(result.data);
        })
        activeMenu('contact')
        setTimeout(function () {
            window.activeNow();
        }, 10000)
    }, [])

    if (!configSite) {
        return <div id="spinner"
                    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-grow text-primary" role="status"></div>
        </div>
    }

    return <>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <h1 className="display-6 mb-5"> {configSite.option.titleContact}</h1>
                        <div className="mb-4">
                            <p><i className="fa fa-phone-alt me-3"></i>{configSite.site.phone}</p>
                            <p><i className="fa fa-envelope me-3"></i>{configSite.site.email}</p>
                            <p><i className="fa fa-map-marker-alt me-3"></i>{configSite.site.branch.address}</p>
                        </div>
                    </div>
                    <div
                        className="col-lg-6 wow fadeIn"
                        data-wow-delay="0.5s"
                        style={{minHeight: 450}}
                    >
                        <div className="position-relative rounded overflow-hidden h-100">
                            <iframe
                                className="position-relative w-100 h-100"
                                src={configSite.site.branch.link}
                                style={{minHeight:450, border:0}}
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
}

export default Contact