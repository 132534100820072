export const FormatNumber = (num) =>{
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const createParame = (obj) => {
    var str = "";
    for (var key in obj) {
        if (str !== "") {
            str += "&";
        }
        str += key + "=" + obj[key];
    }
    return str;
}

const Helper = {
    FormatNumber,
    createParame,
};
export default Helper;