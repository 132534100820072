import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import logo from '../images/logo.png'
import SelectLanguage from "./SelectLanguage";

function Header(props) {
    useEffect(() => {
        activeMenu(props.menus);
    }, [props.menus]);

    const activeMenu = (menus) => {
        if (!menus) {
            return false;
        }

        setTimeout(function () {
            window.onscroll = function () {
                let navbarHead = document.getElementById("navbar-head");
                if (document.documentElement.scrollTop > 300) {
                    navbarHead.classList.add('shadow-sm');
                    navbarHead.style.top = 0;
                } else {
                    navbarHead.classList.remove('shadow-sm');
                    navbarHead.style.top = -100;
                }
            };
        }, 100);
    }

    if (!props.menus) {
        return <div id="spinner"
                    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-grow text-primary" role="status"></div>
        </div>
    }
    return <>

        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5" id='navbar-head'>
            <Link to={`/`} title="OceanCapital" className="navbar-brand d-flex align-items-center">
                <h1 className="m-0">
                    <img className="img-fluid me-3" src={logo} alt="OceanCapital"/>
                </h1>
            </Link>
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav mx-auto rounded pe-4 py-3 py-lg-0">
                    <a href="/" className="nav-item nav-link" id='home'> {props.menus.home}</a>
                    <a href="/about" className="nav-item nav-link" id='about'> {props.menus.about}</a>
                    <a href="/service" className="nav-item nav-link" id="service"> {props.menus.service}</a>
                    <a href="/news" className="nav-item nav-link" id="news"> {props.menus.blog}</a>
                    <a href="/careers" className="nav-item nav-link" id="careers"> {props.menus.careers}</a>
                    <a href="/contact" className="nav-item nav-link" id="contact"> {props.menus.contact}</a>
                </div>
            </div>
            <div id={`select-lange`}>
               <SelectLanguage/>
            </div>
        </nav>
    </>
}

export default Header

