import React, {useEffect, useState} from 'react';


import icon1 from '../images/icon1.png';
import icon2 from '../images/icon2.png';
import icon3 from '../images/icon3.png';
import blogarge from '../images/blog-photo-large.jpg';
import worldMap from '../images/world_map.png';
import {getConfig, getFeaturesArticles, activeMenu} from "./Components/HomeComponent";
import SlidePartner from "./Components/SlidePartner";

function Home() {
    const [linkVideo, setLinkVideo] = useState('');
    const [configSite, setConfigSite] = useState(null);
    const [articles, setArticles] = useState([]);
    useEffect(() => {
        getConfig().then(result => {
            setLinkVideo(result.data.site.header_notice);
            setConfigSite(result.data);
        })

        getFeaturesArticles().then(result => {
            setArticles(result.data);
        })
        activeMenu('home')
        setTimeout(function () {
            window.activeNow();
        }, 10000)
    }, [])
    const renderLinkTrademark = (items, keyItem) => {

        if (!items) {
            return <></>
        }
        return items.map((item, index) => {
            return <li className="text-light" key={`${keyItem}-${index}`}>
                <a href={item.link} target="_blank">{item.nameLink}</a>
            </li>
        })
    }
    useEffect(() => {
        if (configSite) {
            setTimeout(function () {
                window.activeNow();
            }, 1000);
        }
    },[configSite])

    if (!configSite) {
        return false;
    }


    return <>
        <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                <div className="video-container">
                    <iframe
                        src={`https://www.youtube.com/embed/${linkVideo}?controls=0&autoplay=1&mute=1&playsinline=1&playlist=${linkVideo}&loop=1`}>
                    </iframe>

                </div>
            </div>
        </div>
        <SlidePartner/>
        <div className="container facts py-5 about-section">
            <div className="container">
                <div className="row g-5">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
                        <h6 className="text-center">{configSite.option.titleAboutHome}</h6>
                        <h4 className="mb-5">{configSite.option.textAboutHome}</h4>
                    </div>
                    <div className="row row-counter-up">
                        <div className="col-sm-3">
                            <div className="about-item-service wow fadeInUp" data-wow-delay="0.1s">
                                <h4 className="text-center" data-toggle="counter-up">12</h4>
                                <p className="fs-6">{configSite.option.about1}</p>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="about-item-service wow fadeInUp" data-wow-delay="0.2s">
                                <h4 className="text-center" data-toggle="counter-up">12500</h4>
                                <p className="fs-6">{configSite.option.about2}</p>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="about-item-service wow fadeInUp" data-wow-delay="0.3s">
                                <h4 className="text-center" data-toggle="counter-up">40000</h4>
                                <p className="fs-6">{configSite.option.about3}</p>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="about-item-service wow fadeInUp" data-wow-delay="0.4s">
                                <h4 className="text-center" data-toggle="counter-up">8</h4>
                                <p className="fs-6">{configSite.option.about4}</p>
                            </div>
                        </div>
                    </div>
                    <img src={worldMap}/>
                </div>
            </div>
        </div>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                        <h4 className="mb-3">{configSite.option.menu.service}</h4>
                        <p className="mb-4" style={{width: '50%'}}>
                            {configSite.option.service.descriptions}
                        </p>
                        <div className="row g-3">
                            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.2s">
                                <div className="bg-gr bg-gr-1 rounded">
                                    <i><img src={icon1}/></i>
                                    <h5 className="text-light mt-3"> {configSite.option.service.distributingcosmetics.name}</h5>
                                    <p className="text-light fs-6">{configSite.option.service.distributingcosmetics.descriptions}</p>
                                    <ul className="list-unstyled mt-5">
                                        {renderLinkTrademark(configSite.option.service.distributingcosmetics.trademark, 'service')}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.4s">
                                <div className="bg-gr bg-gr-2 rounded">
                                    <i><img src={icon2}/></i>
                                    <h5 className="text-light mt-3">{configSite.option.service.clinic.name}</h5>
                                    <p className="text-light fs-6">{configSite.option.service.clinic.descriptions}</p>
                                    <ul className="list-unstyled mt-5">
                                        {renderLinkTrademark(configSite.option.service.clinic.trademark, 'clinic')}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.6s">
                                <div className="bg-gr bg-gr-3 rounded">
                                    <i><img src={icon3}/></i>
                                    <h5 className="text-light mt-3">{configSite.option.service.foods.name}</h5>
                                    <p className="text-light fs-6">{configSite.option.service.foods.descriptions}</p>
                                    <ul className="list-unstyled mt-5">
                                        {renderLinkTrademark(configSite.option.service.foods.trademark, 'foods')}
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {(articles[0])?
            <div className="container-xxl py-5">
                <div className="container feature-home">
                    <div className="mx-auto">
                        <h4 className="mb-5">{configSite.option.titleFeature}</h4>
                    </div>
                    <div className="row g-4 justify-content-center">
                        {(articles[0])?
                            <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s"  key={`feature-${articles[0].id}`}>
                                <div className="blog-item rounded h-100">
                                    <img src={articles[0].image} width="100%" style={{marginBottom: 10}}/>
                                    <h4 className="blog-name mt-3" style={{marginTop: '10px !important'}}>
                                        {articles[0].title}{articles[0].title}{articles[0].title}
                                        {/*<span className="blog-time ms-3">2 hours ago</span>*/}
                                    </h4>
                                    <p className="blog-short-desc mt-3">{articles[0].descriptions} {articles[0].descriptions}</p>
                                </div>
                            </div>: <></>
                        }

                        <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            {
                                articles.map((item, index) => {
                                    if (!index) {
                                        return null
                                    }
                                    return  <div className="blog-item-list d-flex rounded mb-3" key={`feature-${item.id}`}>
                                        <img src={item.image} width="120"/>
                                        <div className="ms-3">
                                            <h4 className="blog-name mt-3">{item.title}{item.title}{item.title}
                                                {/*<span className="blog-time ms-3">2 hours ago</span>*/}
                                            </h4>
                                            <p className="blog-short-desc small mt-3">
                                                {item.descriptions}
                                            </p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>: ''

        }

    </>
}

export default Home;