import Axios from "../../Lib/Axios";
export const getConfig = () => {
    return Axios.get(`config`);
}

export const getFeaturesArticles = () => {
    return Axios.get(`home/articles`);
}

export const getListPartner = () => {
    return Axios.get(`/partner`);
}


export const activeMenu = (id) => {
   setTimeout(function () {
       const elementMenuTop = document.querySelectorAll("#navbarCollapse .nav-item");
       for (let i = 0; i < elementMenuTop.length; ++i) {
           elementMenuTop[i].classList.remove("active");
       }

       const element = document.getElementById(id);
       element.classList.add("active");
   }, 1500)
}
