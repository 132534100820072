import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import "../../css/slick.min.css";
import "../../css/slick-theme.min.css";
import {getListPartner} from "./HomeComponent";

function SlidePartner(props) {
    const [content, setContent] = useState(null)
    useEffect(() => {
        getListPartner().then(result => {
            setContent(result.data);
        })
    }, [])

    let settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        className: 'notes-slider',
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    if (!content) {
        return <></>
    }

//slick-current
    return <div className="logo-brand-section wow fadeIn" data-wow-delay="0.1s">
        <div className="container">
            <h4 className="text-center">{content.title}</h4>
            <div className="row">
                <div className="col-lg-12">
                    <div className="logo-brand-inner">
                        <Slider {...settings}>
                            {
                                content.items.map((iteam, index) => {
                                    return <div className="single-logo-item" key={`slider-key-${index}`}>
                                        <img src={iteam.fullImage} alt=""/>
                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SlidePartner;