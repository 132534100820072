import React, {useEffect, useState} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {getConfig} from "../Features/Components/HomeComponent";
import {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Layout(props) {
    const [menus, setMenus] = useState(null);
    const [config, setConfig] = useState(null);
    useEffect(() => {
        getConfig().then(result => {
            setMenus(result.data.option.menu);
            setConfig(result.data);
        }).catch(error => {})
    }, [])

    return <>
        <Header menus={menus}/>

        {props.children}

        <Footer menus={menus} config={config}/>
        <ToastContainer/>
    </>
}

export default Layout;