import React, {useEffect, useState} from 'react';

import servicePhoto from '../images/bg-service.jpg';
import tabIcon1 from '../images/tab-icon1.svg';
import tabIcon2 from '../images/tab-icon2.svg';
import tabIcon3 from '../images/tab-icon3.svg';
import tabPhoto from '../images/tab-photo.png';
import tabItemPhoto from '../images/tab-item-photo.png';
import {getService} from "./Components/ServiceRequets";
import {activeMenu} from "./Components/HomeComponent";
import {Link} from "react-router-dom";

let tab1 = 1;
let tab2 = 2;
let tab3 = 3;

function Services(props) {
    const [title, setTitle] = useState(null);
    const [descriptions, setDescriptions] = useState([]);
    const [content, setcontent] = useState([]);
    const [tabContent, setTabcontent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tabActive, setTabActive] = useState(tab1);
    const queryParameters = new URLSearchParams(window.location.search)

    useEffect(() => {
        setLoading(true)
        getService().then(result => {
            setTitle(result.data.title)
            setDescriptions(result.data.descriptions.split("\n"))
            setcontent(result.data.content)
            setTabcontent(result.data.content.cosmetics)
        }).finally(() => {
            setLoading(false)
        })
        activeMenu('service')
    }, [])

    useEffect(() => {
        if (Number(queryParameters.get("tab"))) {
            setTabActive(Number(queryParameters.get("tab")))
            const el = document.getElementById('tab-scroll');
            el.scrollIntoView();
        }
    }, [queryParameters.get("tab")])

    useEffect(() => {
        let tabCurrent = Number(queryParameters.get("tab"));
        if (tabCurrent && content) {
            if (tabCurrent === 1) {
                activeTab(tabCurrent, content.cosmetics);
            }

            if (tabCurrent === 2) {
                activeTab(tabCurrent, content.clinic);
            }

            if (tabCurrent === 3) {
                activeTab(tabCurrent, content.foods);
            }

            document.getElementById('tab-scroll').scrollIntoView();
        }
    }, [content])

    const activeTab = (tab, content) => {
        setTabcontent(content)
        setTabActive(tab)
    }

    if (loading) {
        return <div id="spinner"
                    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-grow text-primary" role="status"></div>
        </div>
    }

    return <>
        <div className="service-banner">
            <div className="service-banner-container">
                <img src={servicePhoto} width="100%"/>
                <div className="service-banner-content">
                    <h1 className="text-white mb-3 text-center">{title}</h1>
                    {
                        descriptions.map((item, index) => {
                            return <p
                                className="text-white mb-3 text-center"
                                key={`service-descriptions-${index}`}
                            >
                                {item}
                            </p>
                        })
                    }
                    {/*<div className={`div-line`}></div>*/}
                </div>
                <div id={`tab-scroll`}></div>
            </div>
        </div>
        <div
            className={`container my-5 py-5 p-5 wow fadeIn bg-primary rounded card-service-${tabActive}`}
             data-wow-delay="0.1s"
             id={`card-service`}
            style={{backgroundColor:'#f00 !important'}}
        >
            <div className="card-service">
                <div className="tabs">
                    <ul id="tabs-nav">
                        {
                            (content.cosmetics) ? <>
                                <li className={tabActive === tab1 ? 'active' : ''}>
                                    <a href="#!" onClick={() => activeTab(tab1, content.cosmetics)}>
                                        <img src={tabIcon1}/> <span>{content.cosmetics.name}</span>
                                    </a>
                                </li>
                                <li className={tabActive === tab2 ? 'active' : ''}>
                                    <a href="#!" onClick={() => activeTab(tab2, content.clinic)}>
                                        <img src={tabIcon2}/> <span>{content.clinic.name}</span>
                                    </a>
                                </li>
                                <li className={tabActive === tab3 ? 'active' : ''}>
                                    <a href="#!" onClick={() => activeTab(tab3, content.foods)}>
                                        <img src={tabIcon3}/> <span> {content.foods.name}</span>
                                    </a>
                                </li>
                            </> : ''

                        }

                    </ul>
                    <div id="tabs-content">
                        {
                            (tabContent) ?
                                <div
                                    className="tab-content mt-3"
                                    //style={{display: tabActive === tab1 ? "block" : "none"}}
                                >
                                    <div className="row">
                                        {
                                            tabContent.descriptions.split("/n").map((item, index) => {
                                                return <p
                                                    className="text-white text-left tab-service-content-text"
                                                    key={`p-service-content-${index}`}
                                                >
                                                    {item}
                                                </p>
                                            })
                                        }

                                    </div>
                                    <div className="row py-5 tab-service-content">
                                        {
                                            tabContent.trademark.map((item, index) => {
                                                let logo = (item.logo)? <div className={`logo-tab`}><img src={item.logo}/></div>:''
                                                let textItem = (item.text)? <p className="text-white py-3">{item.text}</p>:''
                                                let linkItem = (item.link)?  <a href={item.link} target="_blank">{item.nameLink}</a> :''
                                                return <div
                                                    className="col-lg-4 col-md-4 tab-service-content-item"
                                                    key={`tab-service-content-item-${index}`}
                                                >
                                                    {logo}
                                                    {textItem}
                                                    {linkItem}
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            : ''
                        }

                        {/*<div id="tab2" className="tab-content mt-3"*/}
                        {/*     style={{display: tabActive === tab2 ? "block" : "none"}}>*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="col-lg-6 col-md-6">*/}
                        {/*            <img src={tabPhoto}/>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-lg-6 col-md-6">*/}
                        {/*            <p className="text-white">Our Beauty & Cosmetic business unit holds a central*/}
                        {/*                position in our*/}
                        {/*                investment portfolio.*/}
                        {/*                Presently, we engage in the procurement and distribution of a wide range of*/}
                        {/*                beauty*/}
                        {/*                products across various segments, encompassing both domestic offerings and*/}
                        {/*                imports from*/}
                        {/*                esteemed markets such as Korea and Japan.</p>*/}

                        {/*            <p className="text-white">Looking ahead, we have an ambitious plan to establish a*/}
                        {/*                separate*/}
                        {/*                subsidiary company*/}
                        {/*                dedicated solely to the beauty sector. This strategic move will enable us to*/}
                        {/*                develop and*/}
                        {/*                expand our own exclusive line of beauty products, including skincare, cosmetics,*/}
                        {/*                body*/}
                        {/*                care, and more. These products will be distributed both within the domestic*/}
                        {/*                market and*/}
                        {/*                globally, expanding our reach and influence.</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div id="tab3" className="tab-content mt-3"*/}
                        {/*     style={{display: tabActive === tab3 ? "block" : "none"}}>*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="col-lg-6 col-md-6">*/}
                        {/*            <img src={tabPhoto}/>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-lg-6 col-md-6">*/}
                        {/*            <p className="text-white">Our Beauty & Cosmetic business unit holds a central*/}
                        {/*                position in our*/}
                        {/*                investment portfolio.*/}
                        {/*                Presently, we engage in the procurement and distribution of a wide range of*/}
                        {/*                beauty*/}
                        {/*                products across various segments, encompassing both domestic offerings and*/}
                        {/*                imports from*/}
                        {/*                esteemed markets such as Korea and Japan.</p>*/}

                        {/*            <p className="text-white">Looking ahead, we have an ambitious plan to establish a*/}
                        {/*                separate*/}
                        {/*                subsidiary company*/}
                        {/*                dedicated solely to the beauty sector. This strategic move will enable us to*/}
                        {/*                develop and*/}
                        {/*                expand our own exclusive line of beauty products, including skincare, cosmetics,*/}
                        {/*                body*/}
                        {/*                care, and more. These products will be distributed both within the domestic*/}
                        {/*                market and*/}
                        {/*                globally, expanding our reach and influence.</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Services