import React, {useEffect, useState} from 'react';
import APP_CONSTANTS from "../Config/AppConstants";
import Select from 'react-select';

import flagVn from '../images/flag-vn.png'
import flagEn from '../images/flag-en.png'
const options = [
    { value: 'vn', label: <div><img src={flagVn} height="16px" width="21px"/> </div>},
    { value: 'en', label: <div><img src={flagEn} height="16px" width="21px"/> </div>},
];
function SelectLanguage() {
    const [language, setLanguage] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
        let languageLocal = localStorage.getItem(APP_CONSTANTS.language);
        if (!languageLocal) {
            setSelectedOption(options[0])
            setLanguage('vn')
        } else {
            if (languageLocal === 'vn') {
                setSelectedOption(options[0])
            }else {
                setSelectedOption(options[1])
            }
            setLanguage(languageLocal)
        }
    }, []);
    const changeLanguage = (event) => {
        setSelectedOption(event)
        setLanguage(event.value);
        localStorage.setItem(APP_CONSTANTS.language, event.value);
        window.location.reload();
    }

    return  <Select
        defaultValue={selectedOption}
        onChange={changeLanguage}
        options={options}
        value={selectedOption}
    />
}

export default SelectLanguage