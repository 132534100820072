import React, {useEffect, useState} from 'react';
import logo from '../images/logo.png'
import {Link} from "react-router-dom";
import FormContact from "./FormContact";

function Footer(props) {

    if (!props.menus) {
        return <></>
    }

    return <>
        <div className="container appointment my-5 py-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.3s">
                        <h1 className="display-6 text-white mb-5">{props.config.option.formContact.title}</h1>
                        <p className="text-white mb-5" dangerouslySetInnerHTML={{__html: props.config.option.formContact.text}}></p>
                    </div>
                    <FormContact config={props.config}/>
                </div>
            </div>
        </div>
        <div className="bg-light footer wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                        <h1 className="text-primary mb-4">
                            <img className="img-fluid me-3" src={logo} alt="OceanCapital"/>
                        </h1>
                        <p>{props.config.option.descriptions}</p>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <p><i className="fa fa-phone-alt me-3"></i>{props.config.site.phone}</p>
                        <p><i className="fa fa-envelope me-3"></i>{props.config.site.email}</p>
                        <p><i className="fa fa-map-marker-alt me-3"/>{props.config.site.branch.address}</p>
                        <div className="d-flex pt-2">
                            {(props.config.site.twitter)?
                                <a className="btn btn-square me-1" href={props.config.site.twitter}>
                                    <i className="fab fa-twitter"></i>
                                </a>: ''
                            }
                            {(props.config.site.facebook)?
                                <a className="btn btn-square me-1" href={props.config.site.facebook}>
                                    <i className="fab fa-facebook-f"></i>
                                </a>: ''
                            }

                            {(props.config.site.youtobe)?
                                <a className="btn btn-square me-1" href={props.config.site.youtobe}>
                                    <i className="fab fa-youtube"></i>
                                </a>: ''
                            }
                            {(props.config.site.linkedin)?
                                <a className="btn btn-square me-0" href={props.config.site.linkedin}>
                                    <i className="fab fa-linkedin-in"></i>
                                </a>: ''
                            }
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to={`/`} title={props.menus.home} className="btn btn-link">{props.menus.home}</Link>
                        <Link to={`/about`} title={props.menus.about}
                              className="btn btn-link">{props.menus.about}</Link>
                        <Link to={`/careers`} title={props.menus.careers}
                              className="btn btn-link">{props.menus.careers}</Link>
                        <Link to={`/contact`} title={props.menus.contact}
                              className="btn btn-link">{props.menus.contact}</Link>
                    </div>
                    <div className="col-lg-3 col-md-6" style={{display:"none"}}>
                        <Link to={`/service`} title={props.menus.service}
                              className="btn btn-link">{props.menus.service}</Link>
                        <Link to={`/service?tab=1`}
                              title={props.config.option.service.cosmetics.name}
                              className="btn btn-link"
                        >
                            {props.config.option.service.cosmetics.name}
                        </Link>
                        <Link to={`/service?tab=2`}
                              title={props.config.option.service.clinic.name}
                              className="btn btn-link"
                        >
                            {props.config.option.service.clinic.name}
                        </Link>
                        <Link to={`/service?tab=3`}
                              title={props.config.option.service.distributingcosmetics.name}
                              className="btn btn-link"
                        >
                            {props.config.option.service.distributingcosmetics.name}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="container-fluid copyright bg-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            &copy; 2023, Ocean Capital
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Footer