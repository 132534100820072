import React, {useEffect, useState} from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {detailCareer} from "./CareersRequet";
import PopupApplyComponent from "./PopupApplyComponent";
import {activeMenu, getConfig} from "../Components/HomeComponent";

function CareerDetail(props) {

    let navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [configLang, setConfigLang] = useState(null);
    const [showApply, setShowApply] = useState(false);
    const [configSite, setConfigSite] = useState(null);
    useEffect(() => {
        getConfig().then(result => {
            setConfigSite(result.data);
        })
    },[])

    useEffect(() => {
        setLoading(true)
        detailCareer(params.slug).then(result => {
            setDetail(result.data.detail);
            setConfigLang(result.data.lang);
        }).finally(() => {
            setLoading(false)
        })
        activeMenu('careers')
    }, [params.slug]);

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            search: '',
        },
        onSubmit: values => {
            if (values.search) {
                navigate(`/careers?search=${values.search}`);
            } else {
                navigate(`/careers`);
            }
        },
    })

    if ((loading && !configLang) || !configSite) {
        return <div id="spinner"
                    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-grow text-primary" role="status"></div>
        </div>
    }

    if (!detail && !configLang) {
        navigate(`/careers`);
    }

    return <div className={`careers-detail`}>
        <div
            className="container-fluid page-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
        >
            <div className="container py-5 careers-detail-box-search">

                <form
                    className="d-flex box-search"
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                >
                    <input
                        type="text"
                        className="search-input"
                        id="search"
                        placeholder={configSite.option.seachJob}
                        style={{boxShadow: "none"}}
                        autoComplete='off'
                        value={formik.values.search}
                        name='search'
                        onChange={formik.handleChange}
                    />
                    <button className="btn btn-primary bg-primary py-3 px-5" type="submit">
                        Search
                    </button>
                </form>

            </div>
        </div>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row job-details">
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <div className="intro-job p-5">
                            <h1 className="text-primary mb-4">
                                {detail.title}
                            </h1>
                            <div className="row">
                                <div className="d-flex intro-job-title">
                                    <div className="intro-job-inner"><span>{configLang.salary}</span></div>
                                    <div className="intro-job-inner"><b>{detail.salary}</b></div>
                                </div>
                                <div className="d-flex intro-job-title">
                                    <div className="intro-job-inner"><span>{configLang.part}</span></div>
                                    <div className="intro-job-inner"><b>{detail.part}</b></div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="d-flex intro-job-title">
                                    <div className="intro-job-inner"><span>{configLang.level}</span></div>
                                    <div className="intro-job-inner"><b>{detail.level}</b></div>
                                </div>
                                <div className="d-flex intro-job-title">
                                    <div className="intro-job-inner"><span>{configLang.address}</span></div>
                                    <div className="intro-job-inner"><b>{detail.address}</b></div>
                                </div>
                            </div>
                        </div>

                        <div className="detail-job py-5" dangerouslySetInnerHTML={{__html: detail.content}}>

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <button
                            className="btn btn-primary bg-primary py-3 px-5 w-100 mb-3"
                            onClick={() => setShowApply(true)}
                        >{configLang.apply}
                        </button>
                        <button className="btn btn-round bg-white py-3 px-5 w-100 mb-3">{configLang.share}</button>

                    </div>
                </div>
            </div>
        </div>
        <PopupApplyComponent
            configLang={configLang}
            show={showApply}
            hidePopup={setShowApply}
            id={detail.id}
        />
    </div>
}

export default CareerDetail