import React, {useState} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from 'react-toastify';
import {contact} from "../Features/Careers/CareersRequet";

function FormContact(props) {
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(null);
    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: false,
        initialValues: {
            name: '',
            message: '',
            email: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('This field is required'),
            message: Yup.string().required('This field is required'),
            email: Yup.string().email("Invalid Mail format")
                .required("This field is required")
        }),
        onSubmit: values => {
            setLoading(true)
            setServerError(null)
            contact(props.id, values).then(result => {
                toast.success(result.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                formik.resetForm();

            }).catch(error => {

                if (error.response.status !== 400) {
                    return setServerError(error.response.data.message)
                }

                let errorMessage = error.response.data.message;
                if (typeof errorMessage == 'string') {
                    setServerError(errorMessage);
                    return false;
                }

                for (const item in errorMessage) {
                    formik.setFieldError(item, errorMessage[item][0]);
                }

            }).finally(() => {
                setLoading(false)
            })
        },
    })

    return <>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <div className="rounded form-contact">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row g-3">
                        <div className="col-sm-6">
                            <div className="form">
                                <label className="text-light mb-3" htmlFor="name">
                                    Full name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder="Full name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.name ?
                                    <span className="error">{formik.errors.name}</span> : ''}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form">
                                <label className="text-light mb-3" htmlFor="gmail">
                                    Email <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="gmail"
                                    name="email"
                                    placeholder="Email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                                {formik.errors.email ? <span className="error">{formik.errors.email}</span> : ''}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form">
                                <label className="text-light mb-3" htmlFor="message">
                                    Message <span className="text-danger">*</span>
                                </label>
                                <textarea
                                    className="form-control"
                                    placeholder="Message"
                                    id="message"
                                    name='message'
                                    style={{height: 100, resize:"none"}}
                                    onChange={formik.handleChange}
                                    value={formik.values.message}
                                >
                                    {formik.values.message}
                                </textarea>
                                {formik.errors.message ? <span className="error">{formik.errors.message}</span> : ''}
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary bg-primary py-3 px-5" type="submit" disabled={loading}>
                                {loading?'Sending': 'Submit'}
                            </button>
                            {serverError ? <span className="error" style={{display:"block", textAlign:"center"}}>{serverError}</span> : ''}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
}

export default FormContact