import React, {useEffect, useState} from 'react';

import aboutPhoto from '../images/about-photo.png';

import {activeMenu, getConfig} from "./Components/HomeComponent";
import {getOurTeams} from "./Components/AboutRequet";
import SlidePartner from "./Components/SlidePartner";

function About() {

    const [textAbout, setTextAbout] = useState(null);
    const [ourTeams, setOurTeams] = useState(null);
    const [textItem, setTextItem] = useState(null);
    const [tabActive, setTabActive] = useState(0);
    const [contentTab, setContentTab] = useState(0);

    useEffect(() => {

        getConfig().then(result => {
            setTextAbout(result.data.option.about.text.split("<br/>"))
            setTextItem(result.data.option.about)
            setContentTab(result.data.option.about.vision);
        })

        getOurTeams().then(result => {
            setOurTeams(result.data)
        })
        activeMenu('about');
        setTimeout(function () {
            window.activeNow();
        }, 10000)
    }, [])

    const activeTabAbout = (content, tab) => {
        setTabActive(tab)
        setContentTab(content)
    }

    if (!textAbout) {
        return false;
    }

    return <>
        <div className="about-banner row" style={{maxWidth:'100%'}}>
            <div className="col-lg-7 order-lg-1 order-2 col-sm-12 custom-border">
                <div className="about-intro">
                    <h4>Ocean Capital</h4>
                    {
                        textAbout.map((item, index) => {
                            return <p key={`about-key-${item}`}>{item}</p>;
                        })
                    }
                </div>
            </div>
            <div className="col-lg-5 order-lg-2 order-1 col-sm-12 custom-border">
                <img src={aboutPhoto} width="100%"  style={{borderRadius:5}}/>
            </div>
        </div>
        <div className="container-xxl py-5 about-tab-action">
            <div className="container">
                <div className="row g-5">
                    <div
                        className="col-lg-4 wow fadeInUp"
                        data-wow-delay="0.1s"
                        onClick={()=>activeTabAbout(textItem.vision, 0)}
                    >
                        <div className={`rounded text-center p-4 ${(tabActive === 0)? 'bg-primary': ' bg-light'}`}>
                            <img src={(tabActive === 0)?textItem.vision.icon: textItem.vision.icongray} width="60"/>
                            <h5 className={`mt-3 ${(tabActive === 0) ? 'text-white' : 'text-gray '}`}>
                                {textItem.vision.title}
                            </h5>
                        </div>
                    </div>
                    <div
                        className="col-lg-4 wow fadeInUp"
                        data-wow-delay="0.1s"
                        onClick={()=>activeTabAbout(textItem.mission, 1)}
                    >
                        <div className={`rounded text-center p-4 ${(tabActive === 1)? 'bg-primary': ' bg-light'}`}>
                            <img src={(tabActive === 1)?textItem.mission.icon: textItem.mission.icongray} width="60"/>
                            <h5 className={`mt-3 ${(tabActive === 1) ? 'text-white' : 'text-gray '}`}>
                                {textItem.mission.title}
                            </h5>
                        </div>
                    </div>
                    <div
                        className="col-lg-4 wow fadeInUp"
                        data-wow-delay="0.1s"
                        onClick={()=>activeTabAbout(textItem.coreValue, 2)}
                    >
                        <div className={`rounded text-center p-4 ${(tabActive === 2)? 'bg-primary': ' bg-light'}`}>
                            <img src={(tabActive === 2)?textItem.coreValue.icon: textItem.coreValue.icongray} width="60"/>
                                <h5 className={`mt-3 ${(tabActive === 2) ? 'text-white' : 'text-gray '}`}>
                                    {textItem.coreValue.title}
                                </h5>
                        </div>
                    </div>
                </div>
                <div className="row g-5 mt-3">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <img src={contentTab.image} width="100%" style={{borderRadius:5}} />
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                        <p className="txt-about">{contentTab.content}</p>
                    </div>
                </div>
            </div>
        </div>
        <SlidePartner/>
        {
            (ourTeams)?<div className="bg-primary py-5">
                <div className="container">
                    <div className="mx-auto">
                        <h3 className="text-white" style={{textTransform:'uppercase'}}>{ourTeams.title}</h3>
                        <h5 className="mb-5 text-white"> {ourTeams.descriptions}</h5>
                    </div>
                    <div className="row g-4">
                        {
                            ourTeams.items.map((item, index) => {
                                return <div
                                    className="col-lg-6 col-md-12 wow fadeInUp"
                                    data-wow-delay="0.1s"
                                    key={`our-teams-${index}`}
                                >
                                    <div className="team-item our-teams rounded d-flex bg-white">
                                        <img src={item.fullImage} alt={item.title}/>
                                        <div className="text-lg-start p-4">
                                            <h5>{item.title}</h5>
                                            <p>{item.descriptions}</p>
                                        </div>
                                    </div>
                                </div>;
                            })
                        }
                    </div>
                </div>
            </div>: ''
        }
    </>
}

export default About;