import React, {useEffect, useState} from 'react';
import Pagination from "react-js-pagination";
import {Link, useParams, useNavigate } from "react-router-dom";
import {createParame} from "../../Lib/Helper";
import {useFormik} from "formik";
import {listCareers} from "./CareersRequet";
import carrer from '../../images/carrer.jpg';
import {activeMenu, getConfig} from "../Components/HomeComponent";

let search = {};
function ListCareers(props) {

    let navigate = useNavigate();
    const params = useParams();
    const queryParameters = new URLSearchParams(window.location.search)
    const [textCareers, settextCareers] = useState([]);
    const [careersItems, setCareersItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(0);
    const [pageCurrunt, setPageCurrunt] = useState(1);
    const [configSite, setConfigSite] = useState(null);
    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            search: '',
        },
        onSubmit: values => {
            if (values.search) {
                navigate(`/careers?search=${values.search}`);
            } else {
                navigate(`/careers`);
            }
        },
    })

    useEffect(() => {
        getConfig().then(result => {
            settextCareers(result.data.option.titleCareers.split("/n"));
            setConfigSite(result.data);
        })
    },[])
    useEffect(() => {
        let page = 1
        if (Number(params.page) > 0) {
            page = Number(params.page);
        }
        let keySearch = '';
        if (queryParameters.get("search")) {
            keySearch = queryParameters.get("search");
        }

        getArticle(page, keySearch);
        setTimeout(() => {
            if (keySearch) {
                search= {search: keySearch}
            }
            formik.setFieldValue('search',keySearch);
        }, 200)
        activeMenu('careers')
    }, [params.page, queryParameters.get("search")])

    const getArticle = (page, keySearch) => {
        setLoading(true)
        listCareers(page, keySearch).then(result => {
            setCareersItems(result.data.list);
            setTotal(result.data.total);
            setLimit(result.data.limit);
            setPageCurrunt(result.data.pageCurrent)
        }).finally(() => {
            setLoading(false)
        })
    }

    const pushUrl = (page) => {
        let strParams = createParame(search);
        navigate(`/careers/${page}?${strParams}`);
    }

    if (loading || !configSite) {
        return <div id="spinner"
                    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-grow text-primary" role="status"></div>
        </div>
    }


    return <div className={`careers`}>
        <div className="container-fluid mb-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="row careers-header">
                <div className="col-md-6 col-sm-12">
                    <img src={carrer} width="100%" style={{borderRadius:10}}/>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="left-career-banner container py-5 px-5">
                        <h3 className="animated slideInDown mb-4">
                            {
                                (textCareers)?  textCareers.map((item, index) => {
                                    return <p
                                        className="mb-3 text-left"
                                        key={`service-descriptions-${index}`}
                                    >
                                        {item}
                                    </p>
                                }): ''
                            }
                        </h3>
                        <div className="mb-3">

                            <div className="bd-example py-3 px-3">
                                <form
                                    className="row g-3"
                                    onSubmit={formik.handleSubmit}
                                    autoComplete="off"
                                >
                                    <div className="col-auto">
                                        <input
                                            type="text"
                                            className="form-control py-3"
                                            id="search"
                                            placeholder={configSite.option.seachJob}
                                            style={{boxShadow:"none"}}
                                            autoComplete='off'
                                            value={formik.values.search || ''}
                                            name='search'
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            className="btn btn-primary bg-primary py-3 px-5"
                                            type="submit"
                                            style={{boxShadow:"none"}}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto" style={{maxWidth:500}}>
                    <h1 className="display-6 mb-5">{configSite.option.titleJob}</h1>
                </div>
                <div className="row g-5">
                    {
                        (total < 1)? <>
                                <div className={`articles`}>
                                    <p className={`no-posts`}>There are no posts yet</p>
                                </div>
                            </>:
                            careersItems.map((item, index) => {
                                return <div
                                    className="col-lg-4 col-md-4 wow fadeInUp career-item"
                                    data-wow-delay="0.1s"
                                    key={`career-item-${index}`}
                                >
                                    <div className="job-item rounded h-100">
                                        <Link to={`/career/${item.slug}`} title={item.title}>
                                            <h6 className="job-item-title">{item.title}</h6>
                                        </Link>
                                        <Link to={`/career/${item.slug}`} title={item.title}>
                                            <p className="mt-3 blog-short-desc">{item.descriptions}</p>
                                        </Link>
                                        <div className={`career-item-footer`}>
                                            <div className="btn-inline locate">
                                                <i className="fa fa-map-marker-alt me-1"></i>{item.address}
                                            </div>
                                            <div className="btn-inline work-time">{item.typeTime}</div>
                                        </div>
                                    </div>
                                </div>
                        })
                    }
                </div>
            </div>
        </div>
        <div className="row">
            <div className="bd-example text-center">
                <nav aria-label="Page navigation example">
                    {total > 1 ?
                        <Pagination
                            activePage={pageCurrunt}
                            itemsCountPerPage={limit}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={(pageNumber) => {
                                if (pageNumber === pageCurrunt) {
                                    return false;
                                }
                                pushUrl(pageNumber);
                            }}
                        />
                        :
                        <></>
                    }
                </nav>
            </div>
        </div>
    </div>
}

export default ListCareers;