import React, {useEffect, useState} from 'react';
import Pagination from "react-js-pagination";
import {Link, useParams, useNavigate } from "react-router-dom";

import {listArticle} from "./AriticelRequets";
import {activeMenu} from "../Components/HomeComponent";

function News(props) {

    let navigate = useNavigate();
    const params = useParams();
    const [articleItems, setArticleItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(0);
    const [pageCurrunt, setPageCurrunt] = useState(1);
    useEffect(() => {
        getArticle()
        activeMenu('news')
    }, [params.page])

    const getArticle = () => {
        let page = 1
        if (Number(params.page) > 0) {
            page = Number(params.page);
        }

        setLoading(true)
        listArticle(page).then(result => {
            setArticleItems(result.data.list);
            setTotal(result.data.total);
            setLimit(result.data.limit);
            setPageCurrunt(result.data.pageCurrent)
        }).finally(() => {
            setLoading(false)
        })
    }


    const pushUrl = (page, ) => {
        navigate(`/news/${page}`);
        // props.history.push()
        // props.history.push(`/news/${page}`);
       // history.push('/news/'+page);
    }

    if (loading) {
        return <div id="spinner"
                    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-grow text-primary" role="status"></div>
        </div>
    }

    if (articleItems.length < 1) {
        return <div className={`articles`}>
            <p className={`no-posts`}>There are no posts yet</p>
        </div>
    }


    return <div className={`articles`}>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-4 justify-content-center">
                    <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="blog-item rounded h-100">
                            <Link to={`/new/${articleItems[0].slug}`} title={articleItems[0].title}>
                                <img src={articleItems[0].fullImage} width="100%"/>
                            </Link>

                            <h2 className="blog-name mt-3">
                                <Link to={`/new/${articleItems[0].slug}`} title={articleItems[0].title}>
                                {articleItems[0].title}
                                </Link>
                                {/*<span className="blog-time ms-3">2 hours ago</span>*/}
                            </h2>
                            {/*<p className="blog-short-desc mt-3">{articleItems[0].descriptions} </p>*/}
                            <Link to={`/new/${articleItems[0].slug}`} title={articleItems[0].title}>
                                <p className="blog-short-desc mt-3">{articleItems[0].descriptions} </p>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 wow fadeInUp colum-right" data-wow-delay="0.5s">
                        {
                            articleItems.map((item, index) => {
                                if (index >= 1 && index <= 3) {
                                    return <div
                                        className="blog-item-list d-flex rounded mb-3"
                                        key={`blog-item-list-${index}`}
                                    >
                                        <Link to={`/new/${item.slug}`} title={item.title}>
                                            <img src={item.fullImage}/>
                                        </Link>
                                        <div className="ms-3">
                                            <h2 className="blog-name mt-3">
                                                <Link to={`/new/${item.slug}`} title={item.title}>{item.title}</Link>
                                                {/*<span className="blog-time ms-3">2 hours ago</span>*/}
                                            </h2>
                                            <Link to={`/new/${item.slug}`} title={item.title}>
                                                <p className="blog-short-desc small mt-3"> {item.descriptions} </p>
                                            </Link>
                                        </div>
                                    </div>
                                }
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container">
                <div className="row g-5">
                    {
                        articleItems.map((item, index) => {
                            if (index > 3) {
                                return <div
                                    className="col-lg-4 col-md-4 wow fadeInUp"
                                    data-wow-delay="0.1s"
                                    key={`blog-item-list-${index}`}
                                >
                                    <div className="blog-item rounded h-100">
                                        <Link to={`/new/${item.slug}`} title={item.title}>
                                            <img src={item.fullImage} width="100%"/>
                                        </Link>
                                        <h2 className="blog-name mt-3">
                                            <Link to={`/new/${item.slug}`} title={item.title}>
                                            {item.title}
                                            </Link>
                                            {/*<span className="blog-time ms-3">2 hours ago</span>*/}
                                        </h2>
                                        <Link to={`/new/${item.slug}`} title={item.title}>
                                            <p className="blog-short-desc small mt-3"> {item.descriptions} </p>
                                        </Link>

                                    </div>
                                </div>
                            }
                        })
                    }

                </div>
                <div className="row">
                    <div className="bd-example text-center">
                        <nav aria-label="Page navigation example">
                            {total > 1 ?
                                <Pagination
                                    activePage={pageCurrunt}
                                    itemsCountPerPage={limit}
                                    totalItemsCount={total}
                                    pageRangeDisplayed={5}
                                    onChange={(pageNumber) => {
                                        if (pageNumber === pageCurrunt) {
                                            return false;
                                        }
                                         pushUrl(pageNumber);
                                    }}
                                />
                                :
                                <></>
                            }
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default News;