import React, {useEffect, useState} from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";

import {detailArticle} from "./AriticelRequets";
import {activeMenu} from "../Components/HomeComponent";

function DetailArticle(props) {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [otherArticles, setOtherArticles] = useState([]);
    const [titleOther, setTitleOther] = useState('');

    useEffect(() => {

        setLoading(true)
        detailArticle(params.slug).then(result => {
            setDetail(result.data.detail);
            setOtherArticles(result.data.otherArticles);
            setTitleOther(result.data.title);
            // setDetail(result.data.detail);
        }).finally(() => {
            setLoading(false)
        })
        activeMenu('news')
    }, [params.slug]);

    if (loading) {
        return <div id="spinner"
                    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-grow text-primary" role="status"></div>
        </div>
    }

    return <>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-4 justify-content-center">
                    {(detail) ? <div className="p-5">
                        <h3 className="blog-title">{detail.title}</h3>
                        <i>{detail.descriptions}</i>
                        <div className={`detail-article-content`} dangerouslySetInnerHTML={{__html: detail.content}}>

                        </div>
                    </div> : <h3 className={`article-null`}>The article does not exist</h3>
                    }
                </div>
            </div>
        </div>
        <div className="container-fluid py-5 wow fadeIn articles" data-wow-delay="0.1s">
            <div className="container">
                <h4 className="mb-5">{titleOther}</h4>

                <div className="row g-5">
                    {
                        otherArticles.map((item, index) => {
                           return <div className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="blog-item rounded h-100">
                                    <Link to={`/new/${item.slug}`} title={item.title}>
                                      <img src={item.fullImage} width={`100%`}/>
                                    </Link>
                                    <h2 className="blog-name mt-3">
                                        <Link to={`/new/${item.slug}`} title={item.title}>{item.title}</Link>
                                        {/*<span className="blog-time ms-3">2 hours ago</span>*/}
                                    </h2>
                                    <Link to={`/new/${item.slug}`} title={item.title}>
                                        <p className="blog-short-desc small mt-3"> {item.descriptions} </p>
                                    </Link>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    </>
}

export default DetailArticle