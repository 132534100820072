import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useFormik} from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import {applyJob} from "./CareersRequet";

function PopupApplyComponent(props) {

    const [fileCv, setFileCv] = useState(null)
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(null);
    const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone({
        maxFiles: 1,
        maxSize: 5242880,
        accept: {
            'application/pdf': []
        }
    });
    useEffect(() => {
        if (acceptedFiles[0]) {
            setFileCv(acceptedFiles[0]);
            formik.setFieldValue("file", 1);
        }

    }, [acceptedFiles])

    const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: false,
        initialValues: {
            name: '',
            phone: '',
            email: '',
            file: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('This field is required'),
            phone: Yup.string().matches(phoneRegex, 'Phone number is not valid')
                .required('This field is required'),
            email: Yup.string().email("Invalid Mail format")
                .required("This field is required"),
            file: Yup.string().required('This field is required')
        }),
        onSubmit: values => {

            setLoading(true)
            setServerError(null)
            applyJob(props.id, values, fileCv).then(result => {
                toast.success(result.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                props.hidePopup(false)
            }).catch(error => {

                if (error.response.status !== 400) {
                    return setServerError(error.response.data.message)
                }

                let errorMessage = error.response.data.message;
                if (typeof errorMessage == 'string') {
                    setServerError( errorMessage);
                    return false;
                }

                for (const item in errorMessage) {
                    formik.setFieldError(item, errorMessage[item][0]);
                }

            }).finally(() => {
                setLoading(false)
            })
        },
    })
    useEffect(() => {
        let bodyElement = document.getElementById("body-root");
        if (props.show) {
            bodyElement.style.overflow = "hidden";
        }
        return () => {

            setFileCv(null);
            bodyElement.removeAttribute('style');
        }

        formik.resetForm()
    }, [props.show]);

    const removeFileCV = () => {
        setFileCv(null);
        formik.setFieldValue("file", null);
    }

    if (!props.show) {
        return false
    }

    return <div className={`wrapper-popup`}>
        <div className={`content-popup`}>
            <div className={`content-popup-header`}>
                <h5 className="modal-title" id="staticBackdropLabel">
                    {props.configLang.apply}
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => props.hidePopup(false)}
                >

                </button>
            </div>
            <form
                onSubmit={formik.handleSubmit}
                className='auth-form'
                autoComplete="off"
            >
                <div className={'form-group'}>
                    <label>{props.configLang.name} <span>*</span></label>
                    <input
                        type="text"
                        name="name"
                        placeholder={props.configLang.name}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        autoComplete="off"
                    />
                    <i className="fa fa-warning"></i>
                    {formik.errors.name ? <span className="error">{formik.errors.name}</span> : ''}
                </div>
                <div className={'form-group'}>
                    <label>{props.configLang.email} <span>*</span></label>
                    <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        autoComplete="off"
                    />
                    <i className="fa fa-warning"></i>
                    {formik.errors.email ? <span className="error">{formik.errors.email}</span> : ''}
                </div>
                <div className={'form-group'}>
                    <label>{props.configLang.phone} <span>*</span></label>
                    <input
                        type="text"
                        name="phone"
                        placeholder={props.configLang.phone}
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        autoComplete="off"
                    />
                    <i className="fa fa-warning"></i>
                    {formik.errors.phone ? <span className="error">{formik.errors.phone}</span> : ''}
                </div>

                <div className={'form-group'}>
                    <label>{props.configLang.upload} <span>*</span></label>
                    <input
                        type="text"
                        name="file"
                        placeholder=""
                        value={formik.values.file}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        style={{display: "none"}}
                    />
                    {
                        fileCv? <div className={`file-select`}>
                                <p>{fileCv.name}</p>
                                <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                    onClick={() => removeFileCV()}
                                />
                            </div>:
                            <div {...getRootProps()} className={`dropzone-wrapper`}>
                                <input {...getInputProps()} multiple={false}/>

                                <div className="dropzone-desc">
                                    {
                                        isDragActive ?<p>Drop the files here ...</p>:
                                            <>
                                                <i className="glyphicon glyphicon-download-alt"></i>
                                                <p>{props.configLang.dropText}</p>
                                            </>
                                    }

                                </div>
                            </div>
                    }
                    {formik.errors.file ? <span className="error">{formik.errors.file}</span> : ''}
                </div>
                <button type={"submit"} disabled={loading}>{!loading?props.configLang.send: 'Sending'} </button>
                {serverError ? <span className="error" style={{display:"block", textAlign:"center"}}>{serverError}</span> : ''}
            </form>

        </div>
    </div>
}

export default PopupApplyComponent;